import React, { useRef } from 'react'
import Moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { openModal } from '../../../../../Library/ModalPopUp';
import DetailSchedule from './DetailSchedule';

export default function FullCalendarSchd(props) {
    const calendarRef = useRef();
    const now = Moment().format("YYYY-MM-DD");

    const eventRender = (data) => {
        let item = data.event;
        const groupData = JSON.parse(item.groupId);
        let is_today = now === Moment(item.start).format("YYYY-MM-DD") ? true : false;

        return (
            <div className={"fc-event-container cursor-pointer "} onClick={() => detailEvent(item)} title={item.title}>
                <div className="fc-day-grid-event fc-start fc-end fc-event-solid-danger fc-event-light fc-draggable">
                    {!groupData.grouptype ? (
                        <>
                            <div className={"fc-content text-primary"}>
                                <span className="fc-time">{Moment(item.start).format("hh:mm a")}</span>
                                <span className="mx-1">&#x2022;</span>
                                <span className="fc-time">{Moment(item.end).format("hh:mm a")}</span>
                            </div>
                            <div className={"text-wrap text-primary"}>
                                <span>{item.title}</span>
                            </div>
                        </>
                    ) : (
                        <div className={"text-wrap font-weight-bolder text-primary"}>
                            <span>{item.title}</span>
                        </div>
                    )}

                    {groupData.label ? (
                        <div className={"text-wrap text-primary"}>
                            <span>{groupData.label}</span>
                        </div>
                    ) : ""}
                    {(groupData.event_room) && (
                        <div className={"text-wrap text-primary"}>
                            <span>Rooms: {" "}
                                {groupData.event_room.map((v, index) => (
                                    <span key={index}>
                                        {v.room ? v.room.g_resourceName + "(" + v.room.g_capacity + ")" : "-"}
                                        {(Object.values(groupData.event_room).length - 1) > index ? ", " : ""}
                                    </span>
                                ))}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        )
    };

    const detailEvent = (data) => {
        openModal({
            message: <DetailSchedule data={data} />
        });
    };
    //eventOrder={"duration,start"}
    return (
        <div>
            <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                initialDate={props.initialDate}
                validRange={{ start: props.paramDate.tgl, end: props.paramDate.tgl_end }}
                plugins={[dayGridPlugin]}
                events={Object.values(props.data).length > 0 ? props.data : []}
                eventContent={(info) => eventRender(info)}
                eventColor='#3788d8'
                
                eventOverlap={true}
                eventTextColor='black'
                displayEventTime={true}
                displayEventEnd={true}
                showNonCurrentDates={true}
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                        info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}
