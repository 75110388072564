import React, { useState } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import DetailCardHeader from "./DetailCardHeader";
import Form from "./Form";
import FormRevisi from "./FormRevisi";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import { openToast } from "../../../../../Library/ToastPopUp";

export default function Detail(props) {
  const mainData = props.data ? props.data : null;
  const sectionCom = props.data ? props.data.section_com : [];
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
  const NavTab = [{ id: 1, name: "Score", key: "Score" }];
  const [navTab, setNavTab] = useState(NavTab);
  const [selectedTab, setSelectedTab] = useState("Score");
  const hasData = sectionCom.filter(item => item.has_access === 1);
  const newDataScore = hasData.map((item) => ({
    score: item.score || "", // If score is null, default it to 0
    wg_com_id: item.wg_com_id,
  }));

 
  const [dataNilai, setDataNilai] = useState(newDataScore);
  const filterData = dataNilai.filter(item => item.score !== "");
 
  const removeZeroScoreComponents = () => {
    const filteredComponents = dataNilai.filter((item) => item.score !== "");
    return filteredComponents;
  };

  const checkScoreComponents = () => {
    const filteredComponents = dataNilai.filter((item) => item.score === "");
      if (Object.values(filteredComponents).length > 0) {
        return 1;
      } else {
        return 0;
      }
  };

  const submitThesisScore = (e) => {
    e.preventDefault();
    if (AuthDecode.remotedBy) {
      openToast({ header: "Error", message: "You cannot access this request" });
    } else {
      const nilaiEmpty = checkScoreComponents();
      //console.log(dataNilai);
       if(nilaiEmpty==1){
           openToast({ header: "Error", message: "Please fill all the score with number" });
       }else{
      //var formid = e.target.id;
      //var target = document.getElementById(formid);
      //var myButton = target.getElementsByClassName("btn-submit")[0];
      var myButton = document.getElementById("btn-kirim");
      var classSpinner = ["spinner", "spinner-white", "spinner-left"];
      var messageReturn = "";

      myButton.classList.add(...classSpinner);
      myButton.textContent = "processing";
      myButton.disabled = true;

      const nilaiRemove = removeZeroScoreComponents();

      var dataPostScore = {
        section_id: mainData.section_id,
        studentid: mainData.studentid,
        ths_id: mainData.id,
        component: nilaiRemove,
      };

      var keys = {
        AppId: AuthDecode.AppID,
        AppEnv: AuthDecode.AppEnv,
        Secretkey: AuthDecode.secretkey,
      };
      var body = {
        parameters: dataPostScore,
        target_data: "SCR_THS_BY_EXAMINER",
      };
      const postData = {
        keys: keys,
        body: body,
      };
      //console.log(postData);
      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
       // console.log(response);
        if ("message" in response.response) {
          messageReturn =
            "Failed save thesis score. Error " + response.response.message.user;
        } else if ("responseCode" in response) {
          var result = response.response.data;
          if (response.responseCode === 201) {
            // messageReturn = "Success update thesis score.";
            submitThesisRevisi(e);
          } else {
            messageReturn = "Failed update thesis score.";
          }
        } else {
          messageReturn = "Unknow.";
        }

        openToast({
          message: messageReturn,
          header: "Info",
        });

        myButton.classList.remove(...classSpinner);
        myButton.disabled = false;
        myButton.innerHTML = "Save Changes";
      });
    }
    }
  };

  const currExaminer = props.data.ths_main_ex ? props.data.ths_main_ex : null;
  const dataRevision = currExaminer.find(
    (item) => item.emp_id === AuthDecode.identity.entityid
  );

  const objParam = {
    revision: decodeURIComponent(
      dataRevision.revision ? dataRevision.revision : ""
    ),
  };
  const [postRev, setPostRev] = useState(objParam);

  const submitThesisRevisi = (e) => {
    e.preventDefault();
    if (AuthDecode.remotedBy) {
      openToast({ header: "Error", message: "You cannot access this request" });
    } else {
      //var formid = e.target.id;
      //var target = document.getElementById(formid);
      //var myButton = target.getElementsByClassName("btn-submit")[0];
      var myButton = document.getElementById("btn-kirim");
      var classSpinner = ["spinner", "spinner-white", "spinner-left"];
      var messageReturn = "";

      //myButton.classList.add(...classSpinner);
      //myButton.textContent = "processing";
      //myButton.disabled = true;
      const utf8Bytes_sbj = new TextEncoder().encode(decodeURIComponent(postRev.revision));
      const subject = btoa(String.fromCharCode.apply(null, utf8Bytes_sbj)); 

      var dataPostRevisi = {
        ths_id: mainData.id,
        revision: subject,
      };

      var keys = {
        AppId: AuthDecode.AppID,
        AppEnv: AuthDecode.AppEnv,
        Secretkey: AuthDecode.secretkey,
      };
      var body = {
        parameters: dataPostRevisi,
        target_data: "SUBMIT_REVISION_BY_EXAMINER",
      };
      const postData = {
        keys: keys,
        body: body,
      };
      //console.log(postData);
      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        //console.log(response);
        if ("message" in response.response) {
          messageReturn =
            "Failed save thesis revision. Error " +
            response.response.message.user;
        } else if ("responseCode" in response) {
          var result = response.response.data;
          if (response.responseCode === 201) {
            messageReturn = "Score & revision notes are saved";
          } else {
            messageReturn = "Failed update Score & revision notes";
          }
        } else {
          messageReturn = "Unknow.";
        }

        openToast({
          message: messageReturn,
          header: "Info",
        });

        // myButton.classList.remove(...classSpinner);
        // myButton.disabled = true;
        // myButton.innerHTML = 'Save Changes';
      });
    }
  };

  const saveScoreRevision = (e) => {
    submitThesisScore(e);
  };


  const dateNow=Moment().format("YYYY-MM-DD");
  const defenseDate=(mainData.defensedate)?(Moment(mainData.defensedate).format("YYYY-MM-DD")):Moment().format("YYYY-MM-DD");
 
 const formDisabled = () => {
  if(defenseDate<dateNow){
    return true;
  }
  return false;
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header bg-light">
        <DetailCardHeader headers={mainData} />
      </div>
      {/*  <div className="d-flex align-items-center ml-0 py-2 ">
                <div className="d-flex ml-3">
                     <div className="navi navi-hover navi-active navi-link-rounded navi-bold d-flex flex-row">
                        {NavTab.map((n, index) => (
                            <div className="navi-item mr-2" key={index} onClick={() => setSelectedTab(n.key)}>
                                <span className={"navi-link " + (n.key === selectedTab ? "active" : "cursor-pointer")}>{n.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>*/}

      <div className="row">
        <div className={"col-md-4 col-lg-4"}>
          <Form
            data={mainData}
            setSelectedSession={props.setSelectedSession}
            dataNilai={dataNilai}
            setDataNilai={setDataNilai}
            formDisabled={formDisabled()}
          />
        </div>
        <div className={"col-md-8 col-lg-8"}>
          <FormRevisi
            data={mainData}
            setSelectedSession={props.setSelectedSession}
            postRev={postRev}
            setPostRev={setPostRev}
          />
        </div>
      </div>

      <div className="text-right px-10 pb-5">
        <button
          className="btn btn-light font-weight-bolder text-uppercase px-9 py-4 mr-5"
          type="button"
          onClick={(e) => {
            props.setSelectedSession({});
          }}
        >
          Back
        </button>
       
        <button
          id="btn-kirim"
          className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit"
          type="button"
          onClick={(e) => {
            saveScoreRevision(e);
          }}
          disabled={Object.values(hasData).length !== Object.values(filterData).length}
        >
          Save Changes
        </button>
      </div>

      {/* 
            <div className="card-body pt-1">
                <div className="row">
                    <div className="col-lg-2 col-xxl-2">
                        <div className="d-flex align-items-center mb-10 bg-light-warning rounded p-1 text-cursor" onClick={(e) => { props.setSelectedSession({}); }} style={{ cursor: "pointer" }}>
                            <span className="symbol symbol-40 symbol-light-warning mr-5">
                                <span className="symbol-label">
                                    <span className="svg-icon svg-icon-xl svg-icon-warning">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1" />
                                                <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) " />
                                            </g>
                                        </svg>
                                    </span>
                                </span>
                            </span>
                            <div className="d-flex flex-column font-weight-bold">
                                <span className="text-dark mb-1 h4">Back</span>
                                <span className="text-muted">go back to thesis list</span>
                            </div>
                        </div>
                    </div>

                </div>


            </div> */}
    </div>
  );
}
